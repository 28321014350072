const formInputFields = document.querySelectorAll("form.dynamic-labels input");

function focusLabelHandler(event) {
  const fieldLabel = event.target.previousElementSibling;
  fieldLabel.classList.add("active");
}

function blurLabelHandler(event) {
  if(event.target.value === "") {
    const fieldLabel = event.target.previousElementSibling;
    fieldLabel.classList.remove("active");
  }
}

formInputFields.forEach(element => {
  element.addEventListener("focus", focusLabelHandler);
  element.addEventListener("blur", blurLabelHandler);
  const fieldLabel = element.previousElementSibling;

  if (fieldLabel !== null) {
    formInputFields.forEach(element => element.blur());
    fieldLabel.addEventListener("click", event => element.focus());
  }
});